import React, { useEffect, useState } from "react";
import { base, logo, menuIcon } from "../assets/index.js";
import { Link } from "react-router-dom";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isVisible =
        prevScrollPos > currentScrollPos || currentScrollPos < 10;
      setPrevScrollPos(currentScrollPos);
      setVisible(isVisible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const className = isOpen ? "" : "hidden";
  const headerClass = visible
    ? "flex fixed opacity-100 "
    : "flex fixed opacity-0 ";
  return (
    <div className="min-h-[110px]">
      <header
        className={`xl:${headerClass} header items-center animation  justify-between py-6 w-10/12 fixed z-10 `}
        // data-aos="fade-zoom-in"
        // data-aos-easing="ease-in-back"
        // data-aos-delay="100"
        // data-aos-offset="0"
      >
        <div className="xl:block flex items-center justify-between">
          <Link to="/">
            <img
              className="sm:w-80 w-64"
              src={logo}
              alt=""
              data-aos="fade-down"
              data-aos-delay="150"
            />
          </Link>
          <img
            className="sm:w-16 w-12 cursor-pointer xl:hidden block"
            onClick={() => setIsOpen(true)}
            src={menuIcon}
            alt="menu-icon"
          />
        </div>

        <div
          className={`navbar-base-div xl:flex items-center gap-10 xl:static absolute left-6 right-6 ${className}`}
        >
          <div className="navbar relative bg-white shadow-2xl xl:py-4 py-8 rounded-lg xl:w-auto w-full mx-auto flex xl:flex-row flex-col xl:items-center list-none xl:gap-8 gap-5 px-8 xl:rounded-full text-[1.1rem] xl:text-base xl:mt-0 mt-6">
            <li
              onClick={() => setIsOpen(false)}
              className="uppercase text-[#2A2A2A] font-bold xl:hidden block absolute right-5 top-3 text-xl cursor-pointer"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              &#x2715;
            </li>
            <li
              className="uppercase text-[#2A2A2A] font-bold xl:hidden block"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              {" "}
              <Link to="/">Home</Link>
            </li>
            <li
              className="uppercase text-[#2A2A2A] font-bold"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              {" "}
              <HashLink smooth to="/#about">
                About
              </HashLink>
            </li>
            <li
              className="uppercase text-[#2A2A2A] font-bold"
              data-aos="fade-down"
              data-aos-delay="300"
            >
              {" "}
              <HashLink smooth to="/#cbd">
                CBD
              </HashLink>
            </li>
            <li
              className="uppercase text-[#2A2A2A] font-bold"
              data-aos="fade-down"
              data-aos-delay="400"
            >
              {" "}
              <HashLink smooth to="/#products">
                Products
              </HashLink>
            </li>
            <li
              className="uppercase text-[#2A2A2A] font-bold"
              data-aos="fade-down"
              data-aos-delay="500"
            >
              {" "}
              <HashLink smooth to="/#news">
                News
              </HashLink>
            </li>
            <li
              className="uppercase text-[#2A2A2A] font-bold"
              data-aos="fade-down"
              data-aos-delay="600"
            >
              {" "}
              <HashLink smooth to="/#company">
                Company
              </HashLink>
            </li>
            <li
              className="uppercase text-[#2A2A2A] font-bold"
              data-aos="fade-down"
              data-aos-delay="700"
            >
              {" "}
              <a
                target="blank"
                href="https://thebase.com/inquiry/macaronicbd-base-shop?_ga=2.10440437.1186797972.1710091892-1923888348.1706323457&_gl=1*120ozqc*_ga*MTkyMzg4ODM0OC4xNzA2MzIzNDU3*_ga_574HL6KGKQ*MTcxMDA5MTg5Mi4yLjEuMTcxMDA5MTkxMS40MS4wLjA."
              >
                Contact
              </a>
            </li>
            <li
              className="uppercase text-[#2A2A2A] font-bold xl:hidden block"
              data-aos="fade-down"
              data-aos-delay="200"
            >
              {" "}
              <a href="https://shop.macaronicbd.co.jp" target="blank">
                Online Store
              </a>
            </li>
            <div className="social-icons list-none flex items-center gap-8 xl:hidden mt-4">
              {/* <li data-aos="fade-up" data-aos-delay="300">
                {" "}
                <Link to="www.twitter.com">
                  <FaTwitter size={28} />
                </Link>{" "}
              </li>
              <li data-aos="fade-up" data-aos-delay="400">
                {" "}
                <Link to="www.instagram.com">
                  <FaInstagram size={28} />
                </Link>{" "}
              </li> */}
            </div>
            <Link to="/">
              <img
                className="xl:hidden block mt-20 w-64"
                src={logo}
                alt=""
                data-aos="fade-down"
                data-aos-delay="150"
              />
            </Link>
          </div>
          <a
            href="https://shop.macaronicbd.co.jp"
            target="blank"
            data-aos="fade-down"
            data-aos-delay="800"
            className="xl:block hidden"
          >
            <img src={base} alt="Base" className="w-16" />
          </a>
        </div>
      </header>
    </div>
  );
};

export default Header;
