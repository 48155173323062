import React from "react";
import { Link } from "react-router-dom";
import { FaBeer, FaInstagram, FaTwitter } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <>
      <div className="footer-wrapper mx-auto flex flex-col items-center sm:pb-40 pb-20">
        <div className="navbar py-4 flex sm:w-auto w-full sm:items-center list-none sm:gap-16 px-8 rounded-full sm:mt-28 mb-14 text-[1.1rem] xl:flex-nowrap flex-wrap justify-center sm:flex-row flex-col gap-5 mt-16">
          <li
            className="uppercase text-[#2A2A2A] font-bold"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {" "}
            <HashLink smooth to="/#about">
              About
            </HashLink>
          </li>
          <li
            className="uppercase text-[#2A2A2A] font-bold"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            {" "}
            <HashLink smooth to="/#cbd">
              CBD
            </HashLink>
          </li>
          <li
            className="uppercase text-[#2A2A2A] font-bold"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            {" "}
            <HashLink smooth to="/#products">
              Products
            </HashLink>
          </li>
          <li
            className="uppercase text-[#2A2A2A] font-bold"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            {" "}
            <HashLink smooth to="/#news">
              News
            </HashLink>
          </li>
          <li
            className="uppercase text-[#2A2A2A] font-bold"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            {" "}
            <HashLink smooth to="/#company">
              Company
            </HashLink>
          </li>
          <li
            className="uppercase text-[#2A2A2A] font-bold"
            data-aos="fade-up"
            data-aos-delay="700"
          >
            {" "}
            <a
              target="blank"
              href="https://thebase.com/inquiry/macaronicbd-base-shop?_ga=2.10440437.1186797972.1710091892-1923888348.1706323457&_gl=1*120ozqc*_ga*MTkyMzg4ODM0OC4xNzA2MzIzNDU3*_ga_574HL6KGKQ*MTcxMDA5MTg5Mi4yLjEuMTcxMDA5MTkxMS40MS4wLjA."
            >
              Contact
            </a>
          </li>
          <li
            className="uppercase text-[#2A2A2A] font-bold"
            data-aos="fade-up"
            data-aos-delay="800"
          >
            {" "}
            <a href="https://shop.macaronicbd.co.jp" target="blank">
              Online Store
            </a>
          </li>
        </div>
        <div className="social-icons list-none flex items-center gap-10 sm:mb-20 mb-14">
          <li data-aos="fade-up" data-aos-delay="300">
            {" "}
            <Link to="https://twitter.com/macaroniCBD">
              <FaTwitter size={40} />
            </Link>{" "}
          </li>
          <li data-aos="fade-up" data-aos-delay="400">
            {" "}
            <Link to="https://www.instagram.com/macaronicbd/">
              <FaInstagram size={40} />
            </Link>{" "}
          </li>
        </div>
        <div className="copyright-div flex items-center justify-between w-full md:flex-row flex-col md:gap-0 gap-6 text-center">
          <p data-aos="fade-up" data-aos-delay="600"></p>
          <p data-aos="fade-up" data-aos-delay="700">
            Copyright ©︎macaroni Inc. All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
