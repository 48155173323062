import React from "react";
import CBD from "./pages/CBD.jsx";
import Home from "./pages/Home.jsx";
import LCP from "./pages/LCP.jsx";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<CBD />} path="/cbd" />
          <Route element={<LCP />} path="/lcp" />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
