import React from "react";
import CBDSection from "../components/CBDSection.jsx";
import { cbdSafe, cbdUsed, whatCbd } from "../assets/index.js";
import Footer from "../components/Footer.jsx";
import ContainerWrapper from "../components/ContainerWrapper.jsx";

const CBD = () => {
  return (
    <ContainerWrapper>
      <div className="content">
        <CBDSection imageSrc={whatCbd} className="sm:w-64 w-48" styles="mt-32 ">
          <p
            data-aos="fade-up"
            className="text-[#006173] font-bold text-2xl mb-5"
          >
            CBDとは？
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium  leading-9"
          >
            CBDは
            <span className="bg-[#FFFE9C]">「カンナビジオール」</span>
            という化合物で、主に大麻植物から抽出される成分です。
            <span className="bg-[#FFFE9C]">少量の使用でも不安や睡眠の問題を軽減する</span>
            という効果が多く報告されています。
          </p>
        </CBDSection>
        <CBDSection imageSrc={cbdUsed} className="sm:w-96 w-64" styles="mt-32 ">
          <p
            data-aos="fade-up"
            className="text-[#006173] font-bold text-2xl mb-5"
          >
            どんな使われ方をしている？
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium  leading-9"
          >
            <span className="bg-[#006173] w-3 h-3 rounded-full inline-block mr-4 "></span>
            <span className="bg-[#FFFE9C]">CBDはオイルやグミ</span>の形でよく使用されますが、
            <span className="bg-[#FFFE9C]">化粧品、バーム、シャンプーなどの多岐にわたる商品</span>
            にも含まれ、近年数多く販売されています。
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium  mt-6 leading-9"
          >
            {" "}
            <span className="bg-[#006173] w-3 h-3 rounded-full inline-block mr-4 "></span>
            アメリカやヨーロッパをはじめとする国々では、CBD成分を含む治療薬が医療用として認可されています。
            <span className="bg-[#FFFE9C]">日本でも治療薬の治験が進行中</span>です。
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium  mt-6 leading-9"
          >
            <br/>CBDをどのように摂取したらよいか分からない、日常にどう取り入れるのがよいか分からない方のために、
            <span className="bg-[#FFFE9C]">以下のような取り組みを行っています。</span>
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium  mt-6 leading-9"
          >
            {" "}
            <span className="bg-[#006173] w-3 h-3 rounded-full inline-block mr-4"></span>
            ご希望の方に
            <span className="bg-[#FFFE9C]">初心者用マニュアルをお渡しする。</span>
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium  mt-6 leading-9"
          >
            {" "}
            <span className="bg-[#006173] w-3 h-3 rounded-full inline-block mr-4 "></span>
            Xにて
            <span className="bg-[#FFFE9C]">#マカロニ水溶性CBDレシピ</span>
            でCBDを活用した
            <span className="bg-[#FFFE9C]">美味しい料理やスイーツのレシピを公開。</span>
          </p>
        </CBDSection>
        <CBDSection imageSrc={cbdSafe} className="sm:w-96 w-64" styles="mt-32 ">
          <p
            data-aos="fade-up"
            className="text-[#006173] font-bold text-2xl mb-5"
          >
            CBDって安全？
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium  mt-6 leading-9"
          >
            CBD には様々なポジティブな効果が報告されていますが、
            <span className="bg-[#FFFE9C]">処方薬との飲み合わせや、意図せず混入する規制成分のリスク</span>
            など、安全性には注意が必要です。<br/>
            マカロニ CBDは、
            <span className="bg-[#FFFE9C]">CBDの安全性確保のための取り組みを積極的に行っています。</span>
            <br/><br/>
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium   leading-9"
          >
            <span className="bg-[#006173] w-3 h-3 rounded-full inline-block mr-4"></span>
            多くのCBD製品が国外での成分分析のみで販売される中、
            <span className="bg-[#FFFE9C]">当社は国内での成分分析も実施し、規制成分の混入を防ぎます。</span>
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium  mt-6 leading-9"
          >
            {" "}
            <span className="bg-[#006173] w-3 h-3 rounded-full inline-block mr-4"></span>
            <span className="bg-[#FFFE9C]">薬剤師の監修のもとで商品を開発しています。</span>
            処方薬との飲み合わせに不安がある方は、
            <span className="bg-[#FFFE9C]">担当医とご相談の上、</span>
            購入をご検討ください。何かご不明点やご相談があれば、お気軽にお問い合わせください。
          </p>
        </CBDSection>
      </div>
      <Footer />
    </ContainerWrapper>
  );
};

export default CBD;
