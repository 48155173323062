import React from "react";
import ContainerWrapper from "../components/ContainerWrapper";
import {
  aboutLeftSide,
  whatCBDRightSide,
  aboutRightSide,
  aboutSide,
  aboutcbd,
  company,
  homebg,
  news,
  prTimes1,
  prTimes2,
  prTimes3,
  prTimes4,
  product1,
  product2,
  product3,
  products,
  productsCBD2,
  productsCircle,
  productsNew,
  text,
  whatCbd,
} from "../assets";
import CBDSection from "../components/CBDSection";
import { FaArrowRight } from "react-icons/fa";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
//
const Home = () => {
  return (
    <ContainerWrapper>
      <div className="hero-section min-h-screen">
        <div
          data-aos="fade-in"
          data-aos-delay="300"
          className="bg-image-div w-full md:bg-cover bg-no-repeat bg-center min-h-screen left-0 right-0 absolute top-0 z-[-1]"
          style={{ backgroundImage: `url(${homebg})` }}
        >
          <div className="text-container -translate-y-1/2 top-1/2 xl:left-28 absolute md:left-14 left-0 md:text-left text-center">
            {/* <p
              data-aos="fade-up"
              data-aos-delay="300"
              className="text-[#EF7A52] font md:text-7xl sm:text-5xl md:leading-tight sm:leading-tight text-4xl leading-tight"
            >
              <span className="text-[#000]">とっておきの</span>
              <br />
              ご自愛習慣。
            </p> */}
            <img
              src={text}
              className="text-image w-[430px] mx-auto md:mx-0"
              data-aos="fade-up"
              data-aos-delay="300"
              alt="japanese-text"
            />
            <p
              data-aos="fade-up"
              data-aos-delay="400"
              className="text-[#000] font-medium mt-6 xl:w-5/12 md:pr-10 md:pl-0 leading-8 md:w-9/12 w-full px-7"
            >
              CBDを試したことがない方にも安心していただけるよう、高品質と安全性にこだわり、生活に溶け込むCBD製品を提供します。
              <br />
              <br />
              全ての方に、自分らしく心安らぐひとときを。
            </p>
          </div>
        </div>
      </div>
      <div className="relative">
        <CBDSection
          id="about"
          imageSrc={aboutcbd}
          className="sm:w-40 w-32"
          styles="xl:w-8/12"
        >
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium xl:w-full leading-9"
          >
            オリジナルCBDの製品開発、販売を中心に、OEMなども承っております。高品質の原料と製品作りのノウハウを生かし、お客様のニーズに寄り添った最高品質のCBD製品を販売、ご提案させていただきます。
          </p>
          <HashLink
            smooth
            to="/#company"
            className="text-[#006173] sm:mt-4 mt-2 sm:mr-10 uppercase sm:ml-auto flex justify-end items-center font-bold md:text-xl mx-auto"
          >
            View More{" "}
            <span className="ml-4 w-10 h-10 rounded-full flex items-center justify-center border-2 border-[#006173]">
              <FaArrowRight size={24} />
            </span>
          </HashLink>
          <img
            data-aos="fade-up"
            className="absolute 2xl:-top-20 2xl:-right-24 2xl:w-[500px] sm:w-[370px] w-[320px] xl:right-0 md:right-32 xl:-top-5 right-0 sm:mt-0 mt-4 cursor-pointer"
            src={aboutSide}
            alt="about-side"
          />
          <img
            className="absolute top-48 md:right-80 sm:left-auto w-[370px] z-[-1] -left-20"
            src={aboutRightSide}
            alt="about-right-side"
          />
        </CBDSection>
        <CBDSection
          id="cbd"
          imageSrc={whatCbd}
          className="sm:w-64 w-48"
          styles="xl:mt-32 xl:w-8/12 mt-64"
        >
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium xl:w-full leading-9"
          >
            CBDは麻から抽出される成分で、痛みの軽減、不安の緩和、睡眠の質の向上、抑うつ症状の軽減などに効果が期待されており、日常のリラックスタイムや、ポジティブな生活をサポートします。
          </p>
          <img
            // data-aos="fade-up"
            className="absolute xl:top-[350px] xl:mt-0  2xl:-right-10 2xl:w-[450px] xl:w-[370px] w-[300px] xl:right-0 md:right-32 right-0 mt-14  cursor-pointer sm:block hidden"
            src={whatCBDRightSide}
            alt="about-side"
          />
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/cbd"
            className="text-[#006173] justify-end sm:mt-4 mt-2 sm:mr-10 uppercase sm:ml-auto flex items-center font-bold md:text-xl mx-auto"
          >
            View More{" "}
            <span className="ml-4 w-10 h-10 rounded-full flex items-center justify-center border-2 border-[#006173]">
              <FaArrowRight size={24} />
            </span>
          </Link>
          <img
            className="absolute -left-20 w-[370px] z-[-1]"
            src={aboutLeftSide}
            alt="about-left-side"
          />
        </CBDSection>
        <CBDSection
          id="products"
          imageSrc={products}
          className="sm:w-48 w-40"
          styles="xl:mt-32 mt-48"
        >
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium xl:w-full leading-9"
          >
            CBD初心者の方からベテランの方までお楽しみいただける、食品やベイプなど、多種多様な製品を取り揃えております。
          </p>
          <div
            className="water-soluble-div relative mt-6"
            data-aos="fade-in"
            data-aos-delay="300"
          >
            <Link to="/lcp" reloadDocument={true}>
              <img
                className="absolute xl:-left-6 z-10 sm:w-28 sm:-left-8 sm:-top-3 -top-5 w-16 -left-5"
                src={productsNew}
                alt="product-new"
              />
              {/* <img
                className="relative mt-6 xl:w-10/12 w-full "
                src={productsCBD2}
                alt="product-CBD"
              /> */}
              <img
                className="transform transition duration-500 md:hover:scale-105 mt-6 xl:w-10/12 w-full "
                src={productsCBD2}
                alt="product-CBD"
              />
            </Link>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="400"
            className="flex items-center sm:gap-10 mt-10 justify-center xl:w-9/12 xl:pl-10 sm:w-11/12 mx-auto xl:mx-0 gap-4 w-10/12"
          >
            <img className="w-4/12" src={product1} alt="product-1" />
            <img className="w-4/12" src={product2} alt="product-2" />
            <img className="w-4/12" src={product3} alt="product-3" />
          </div>
          <img
            className="absolute -z-10 -mt-44"
            src={productsCircle}
            alt="products-circle"
          />
        </CBDSection>

        <CBDSection
          id="news"
          imageSrc={news}
          className="sm:w-36 w-28"
          styles="mt-32 "
        >
          <div
            className="flex items-center xl:gap-14 gap-8 sm:flex-row flex-col"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="news-box text-center w-full">
              <Link to="https://cbd-japan.com/contents/interview-macaroni">
                <img
                  src={prTimes4}
                  alt="prTimes4"
                  className="transform transition duration-500 md:hover:scale-110"
                />
                <p className="xl:text-xl uppercase text-black font-semibold mt-4">
                  CBD JAPAN
                </p>
                <span className="text-black font-medium">
                  CBD JAPAN にて当社のインタビュー記事が掲載されました。
                </span>
              </Link>
            </div>
            <div className="news-box text-center w-full">
              <Link to="https://pococe.presspad.store/read/39742">
                <img
                  src={prTimes1}
                  alt="prTimes1"
                  className="transform transition duration-500 md:hover:scale-110"
                />
                <p className="xl:text-xl uppercase text-black font-semibold mt-4">
                  Poco'ce
                </p>
                <span className="text-black font-medium">
                  Poco'ce 2023年6月号に掲載されました。
                </span>
              </Link>
            </div>
            <div className="news-box text-center w-full">
              <Link to="https://prtimes.jp/main/html/rd/p/000000002.000114456.html">
                <img
                  src={prTimes2}
                  alt="prTimes2"
                  className="transform transition duration-500 md:hover:scale-110"
                />
                <p className="xl:text-xl uppercase text-black font-semibold mt-4">
                  PR TIMES
                </p>
                <span className="text-black font-medium">
                  2023年1月新商品をリリースいたしました。
                </span>
              </Link>
            </div>
            <div className="news-box text-center w-full">
              <Link to="https://www.shufu.co.jp/magazine/ar/backnumber/ar-2022年-9月号/">
                <img
                  src={prTimes3}
                  alt="prTimes3"
                  className="transform transition duration-500 md:hover:scale-110"
                />
                <p className="xl:text-xl uppercase text-black font-semibold mt-4">
                  ar
                </p>
                <span className="text-black font-medium">
                  ar 2022年9月号に掲載されました。
                </span>
              </Link>
            </div>
          </div>
          <button className="text-[#006173] sm:mt-16 mt-10 sm:mr-10 uppercase sm:ml-auto flex items-center font-bold md:text-xl mx-auto">
            View More{" "}
            <span className="ml-4 w-10 h-10 rounded-full flex items-center justify-center border-2 border-[#006173]">
              <FaArrowRight size={24} />
            </span>
          </button>
          <img
            className="absolute -z-10 -mt-20 xl:right-96 right-0"
            src={aboutRightSide}
            alt="about-right-side"
          />
        </CBDSection>
        <CBDSection
          id="company"
          imageSrc={company}
          className="sm:w-48 w-36 relative top-4"
          styles=" mt-32"
        >
          <p
            data-aos="fade-up"
            className="text-[#006173] font-bold sm:text-2xl text-xl mb-3"
          >
            製品開発・OEM
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium xl:w-11/12 leading-9"
          >
            当社では、国内外の外部調査機関を経た高品質原料を取り扱っております。
            水溶性CBDをはじめ、CBDVなどのレアカンナビノイド、テルペンなど本格的な原料を使用した、
            世に出ていないオリジナル製品のOEMを幅広く対応しております。
            ぜひお問合わせください。
          </p>
          <p
            data-aos="fade-up"
            className="text-[#006173] font-bold sm:text-2xl text-xl mt-5 mb-3"
          >
            店舗への卸・コンサルティング
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3f3f3f] font-medium xl:w-11/12 leading-9"
          >
            日常のリラクゼーション、スポーツ後のリカバリー向けなど、
            様々なニーズに対応するCBD製品をご提案させていただきます。
            さらに、商品開発以外にも、他ブランドとの差別化戦略についてのコンサルティングも行っております。
          </p>
          <p
            data-aos="fade-up"
            className="text-[#006173] font-bold sm:text-2xl text-xl mt-5 mb-3"
          >
            OEM実績
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3DAAC9] font-medium xl:w-8/12 leading-9 flex items-center"
          >
            <span
              className="w-0 h-0
    border-t-[10px] border-t-transparent
    border-l-[13px] border-l-[#3DAAC9]
    border-b-[10px] border-b-transparent inline-block mr-4"
            ></span>
            <Link to="https://prtimes.jp/main/html/rd/p/000000009.000061115.html">
              LAKA CHILL様
            </Link>
          </p>
          <p
            data-aos="fade-up"
            className="text-[#3DAAC9] font-medium xl:w-8/12 leading-9 flex items-center"
          >
            <span
              className="w-0 h-0
    border-t-[10px] border-t-transparent
    border-l-[13px] border-l-[#3DAAC9]
    border-b-[10px] border-b-transparent inline-block mr-4"
            ></span>
            <Link to="https://setoasa.com/products/cbd-liquid-grapefruit">
              瀬戸内あさ商店様
            </Link>
          </p>
        </CBDSection>
      </div>
      <Footer />
    </ContainerWrapper>
  );
};

export default Home;
