import React, { useEffect, useState } from "react";
import {
  base1,
  bg,
  circle,
  circle1,
  circle2,
  doc,
  document1,
  document2,
  glass,
  layer,
  layer13,
  layer2,
  layer4,
  layer5,
  layer9,
  logo,
  magzine,
  snsBg,
  teaCup,
  waterSoluble,
  waterSoluble2,
} from "../assets";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import ImageModal from "../components/ImageModal";

const LCP = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Calculate the scroll position
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const isSmallDevice =
        window.innerWidth <= 500 && window.innerWidth >= 430;
      const isSmallerDevice = window.innerWidth <= 430;

      // Set the threshold based on the device size
      const threshold = isSmallDevice ? 6500 : isSmallerDevice ? 5200 : 8400;

      setIsVisible(scrollY >= 1000);

      console.log(scrollY);
      console.log(isVisible);

      // Use the threshold value
      if (scrollY >= threshold) {
        setIsVisible(false);
      }
    };

    // Add event listener for the scroll event
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  return (
    <div className="flex flex-col items-center lcp-container">
      <div
        className="bg-image-div w-full bg-cover min-h-screen left-0 right-0 fixed z-[-1]"
        style={{ backgroundImage: `url(${bg})` }}
      ></div>
      <div className="w-[500px] content-div custom-hero-section relative z-10">
        <img className="brightness-90" src={layer} alt="layer" />
        <div data-aos="fade-up" className="absolute top-0 w-full">
          <Link to="/">
            <img
              data-aos="fade-up"
              className="w-72 mx-auto mt-12 mb-7"
              src={logo}
              alt="logo"
            />
          </Link>
          <div className="text-white mt-10">
            <p
              data-aos="fade-up"
              className="font-semibold italic text-6xl px- tracking-wider lemon-font"
            >
              おいしい
            </p>
            <span
              data-aos="fade-up"
              className="text-[#FFED9A] font-bold text-4xl text-center inline-block w-full"
            >
              {" "}
              ✘
            </span>
            <p
              data-aos="fade-up"
              className="font-semibold italic text-6xl tracking-wider text-right px-1 lemon-font"
            >
              カラダ巡る
            </p>
          </div>
          <div className="text-center mt-4 ">
            <p
              data-aos="fade-up"
              className="font-bold text-7xl text-white shippori-font "
            >
              新発想の
            </p>
            <p
              data-aos="fade-up"
              className="text-[#36606F] leading-normal font-bold text-7xl shippori-font "
            >
              水溶性CBD
            </p>
            <p data-aos="fade-up" className="text-[#36606F] tracking-widest">
              WaterSoluble HempPowder
            </p>
          </div>
        </div>
        <img
          data-aos="fade-up"
          className="absolute bottom-0 z-10 left-2"
          src={waterSoluble}
          alt="water-soluble"
        />
      </div>
      <div className="absolute z-20 top-0 logo-div">
        <Link to="/">
          <img
            // data-aos="fade-up"
            className="mx-auto mt-[4.1rem] mb-7 w-[22rem]"
            src={logo}
            alt="logo"
          />
        </Link>
      </div>
      <img
        src={require("../assets/image.png")}
        className="w-[500px] hero-img z-10"
        alt="hero-background"
      />
      <div className="w-[500px] content-div relative -top-12 second-section">
        <img className="min-h-[820px]" src={layer2} alt="layer" />
        <div data-aos="fade-up" className="absolute top-10 w-full">
          <img
            src={require("../assets/97.png")}
            className="mt-5 mx-auto"
            alt="percentage"
          />
          <div data-aos="fade-up" className="text-white mt-6">
            <p className="italic font-semibold text-4xl px-7 relative -top-6 -skew-y-12 lemon-font">
              たっぷり！
            </p>
            <p className="text-center text-5xl font-bold shippori-font">
              CBD<span className="font-bold text-7xl">20%</span>配合
            </p>
          </div>
          <p
            data-aos="fade-up"
            className="text-center text-white font-semibold text-2xl mt-2"
          >
            5g あたり 1000mg
          </p>
          <div
            data-aos="fade-up"
            className="flex items-center gap-2 mt-5 text-lg px-3"
          >
            <p
              data-aos="fade-up"
              className="bg-white text-[#36606F] w-full font-semibold text-center px-2 py-1 rounded-full"
            >
              MCTオイル
            </p>
            <p
              data-aos="fade-up"
              className="bg-white text-[#36606F] w-full font-semibold text-center px-1 py-1 rounded-full"
            >
              デキストリン※
            </p>
            <p
              data-aos="fade-up"
              className="bg-white text-[#36606F] w-full font-semibold text-center px-2 py-1 rounded-full"
            >
              ビタミンC
            </p>
          </div>
          <p
            data-aos="fade-up"
            className="text-white text-center font-semibold mt-2 text-sm"
          >
            ※食物繊維
          </p>
          <p
            data-aos="fade-up"
            className="text-white text-center font-semibold text-5xl mt-6 lemon-font"
          >
            じゅわっと溶ける。
          </p>
          <p
            data-aos="fade-up"
            className="text-white mt-6 px-4 text-lg leading-loose text-center font-semibold "
          >
            独自製法により溶解性を高めた、
            <span className="bg-[#FFFE9C] text-[#36606F]">
              液体に溶けやすいCBDパウダー
            </span>
            です。
            そのまま食べても美味しく、お食事やお飲み物と一緒に摂取しても味の邪魔をせず、
            むしろ引き立てる新発想のCBDです。
            その他、化粧水に混ぜる、お風呂に入れるなど様々な使い方を通して、
            <span className="bg-[#FFFE9C] text-[#36606F]">
              CBDが日常に”溶け込む”ことを叶えます。
            </span>
          </p>
        </div>
      </div>
      <div className="w-[500px] content-div relative -top-24 third-section z-10">
        <img src={layer13} alt="layer" />
        <div data-aos="fade-up" className="absolute top-10 w-full px-2">
          <p
            data-aos="fade-up"
            className="text-[#36606F] text-3xl leading-loose text-center font-bold shippori-font"
          >
            「おいしい」と「カラダ巡る」を両立した水溶性CBDとは？
          </p>
          <div className="absolute top-96 text-[#36606F]">
            <div className="text-center px-4 font-semibold leading-relaxed text-lg">
              <p data-aos="fade-up" className="text-2xl italic mb-3 lemon-font">
                Point 1
              </p>
              <p data-aos="fade-up" className="mb-3">
                MCTオイル、デキストリンでCBD特有の苦味を抑える事に成功。ビタミンCのほどよい酸味が様々な食材に美味しくマッチします。
              </p>
              <p data-aos="fade-up" className="text-2xl italic mb-3 lemon-font">
                Point 2
              </p>
              <p data-aos="fade-up">
                合成乳化剤は不使用。MCTオイルと共に、消化管粘膜から速やかに吸収されます。
              </p>
            </div>
            <div className="p-5 shadow-[0_20px_60px_5px_rgba(0,0,0,0.3)] mt-10 relative z-10 bg-white bg-opacity-70">
              <p
                data-aos="fade-up"
                className="text-2xl font-bold leading-loose shippori-font"
              >
                カンナビノイドの専門家が推薦する水溶性CBDパウダー
              </p>
              <div className="flex items-start gap-4 mt-5 font-semibold leading-relaxed">
                <img
                  data-aos="fade-up"
                  className="w-full"
                  src={doc}
                  alt="doc"
                />
                <div data-aos="fade-up">
                  <p data-aos="fade-up">佐藤　均</p>
                  <p data-aos="fade-up" className="my-3 text-sm">
                    一般社団法人日本ヘンプ協会　代表理事　昭和大学薬学部教授　薬学博士・薬剤師
                  </p>
                  <p data-aos="fade-up" className="text-xs">
                    東京大学薬学系研究科（製剤学教室）修士課程終了後、金沢大学薬学部助手,富山医科薬科大学付属病院薬剤部助手、
                    アメリカ国立衛生研究所（NIH）・がん研究所（NCI）奨励研究員、スイス・バーゼル研究所客員研究員を経て、東京大学医学部助教授となる。
                    2000年から昭和大学薬学部教授（薬物動態学研究室）書籍「カンナビノイドの科学」監修
                  </p>
                </div>
              </div>
              <p
                data-aos="fade-up"
                className="font-semibold mt-5 leading-loose text-sm"
              >
                MCT微粒子は時間経過とともに水と分離しますが、デキストリン分子を付加し、
                水との親和性を持たせ分離を防ぐことに成功しました。 また、
                MCT微粒子自体は粘膜に直接吸着できるため、粘膜表面を通じて、MCT微粒子内のCBD分子が細胞内に移行することでができます。
              </p>
              <p
                data-aos="fade-up"
                className="font-semibold mt-5 leading-loose text-sm"
              >
                海外で製造された水溶性CBDは大量の界面活性剤を含むものが多く、食品に適さないため、国内製造にこだわりました。
                合成乳化剤は使用せずに食品である中鎖脂肪酸とデキストリンを添加することで食品として摂取できる水溶性CBDを開発しました。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[500px] content-div relative -top-[25em] fourth-section z-10">
        <img src={layer4} alt="layer" />
        <div data-aos="fade-up" className="absolute w-full top-16 px-6">
          <p
            data-aos="fade-up"
            className="text-center text-white font-semibold text-4xl shippori-font"
          >
            安全性について
          </p>
          <p
            data-aos="fade-up"
            className="text-center text-white font-midium mt-6 leading-loose text-lg"
          >
            医療グレードの品質の原料を、検疫所より食品許可を得て輸入しております。また、国内の分析センターにて再度分析を実施し、品質に問題ないことを確認しております。
          </p>
          <div className="flex gap-5 justify-center mt-5">
            <img
              data-aos="fade-up"
              className="w-5/12"
              src={document1}
              alt="document"
            />
            <img
              data-aos="fade-up"
              className="w-5/12"
              src={document2}
              alt="document"
            />
          </div>
          <p
            data-aos="fade-up"
            className="text-center mt-2 text-white font-midium"
          >
            ※現在規制されている成分は一切含まれておりません。
          </p>
          <p
            data-aos="fade-up"
            className="text-center mt-10 text-5xl text-white lemon-font"
          >
            こんなあなたに。
          </p>
          <div className="mt-6 px-3">
            <div className="flex items-center justify-center gap-6">
              <img
                data-aos="fade-up"
                src={circle2}
                className="w-16"
                alt="circle"
              />
              <span
                data-aos="fade-up"
                className="text-white font-semibold text-xl"
              >
                深くリラックスしたい
              </span>
            </div>
            <div className="flex items-center justify-center gap-6 mt-3">
              <img
                data-aos="fade-up"
                src={circle}
                className="w-16"
                alt="circle"
              />
              <span
                data-aos="fade-up"
                className="text-white font-semibold text-xl min-w-[200px]"
              >
                すっきり目覚めたい
              </span>
            </div>
            <div className="flex items-center justify-center gap-6 mt-3">
              <img
                data-aos="fade-up"
                src={circle1}
                className="w-16"
                alt="circle"
              />
              <span
                data-aos="fade-up"
                className="text-white font-semibold text-xl "
              >
                気分の乱れが気になる
              </span>
            </div>
          </div>
          <p
            data-aos="fade-up"
            className="text-white font-semibold text-xl text-center mt-4 leading-relaxed"
          >
            温泉のように、心身の調子を整えるサポートに水溶性CBDがおすすめです。
          </p>
        </div>
      </div>
      <div className="w-[500px] content-div relative -top-[28em] fifth-section">
        <img src={layer5} alt="layer" />
        <div data-aos="fade-up" className="absolute w-full top-16">
          <p
            data-aos="fade-up"
            className="text-center text-5xl font-bold text-[#36606F] lemon-font"
          >
            お召しあがり方
          </p>
          <p
            data-aos="fade-up"
            className="text-center text-xl font-bold text-[#36606F] mt-5 px-4"
          >
            お水、コーヒー、ジュース、ビールなどお好きな飲み物に溶かしてお楽しみください。
          </p>
        </div>
        <div
          data-aos="fade-up"
          className="absolute leading-relaxed bottom-7 pl-4"
        >
          <p
            data-aos="fade-up"
            className="leading-relaxed  text-xl font-bold text-[#36606F] w-8/12 mb-5"
          >
            付属のスプーン
            <span className="bg-[#FFFE9C]">１〜２杯が1回分のめやす</span>
            です。※
          </p>
          <p
            data-aos="fade-up"
            className="text-center bg-[#36606F] text-2xl text-white inline rounded-full py-2 px-14"
          >
            CBD約20~40mg
          </p>
        </div>
      </div>
      <div className="w-[500px] content-div relative -top-[28.1em] sixth-section">
        <img src={layer9} alt="layer" />
        <div data-aos="fade-up" className="absolute w-full top-6">
          <div className="flex items-center justify-center gap-4 w-10/12 mx-auto">
            <FaCheck size={24} color="#FFEF9B" />
            <p
              data-aos="fade-up"
              className="text-xl font-semibold text-[#36606F] lemon-font"
            >
              おやすみ前のリラックスタイムに
            </p>
          </div>
          <div className="flex items-center justify-center gap-4 w-10/12 mx-auto mt-4">
            <FaCheck size={24} color="#FFEF9B" />
            <p
              data-aos="fade-up"
              className="text-xl font-semibold text-[#36606F] lemon-font"
            >
              気分をリフレッシュしたいときに
            </p>
          </div>
          <div className="flex items-center justify-center gap-4 w-10/12 mx-auto mt-4">
            <FaCheck size={24} color="#FFEF9B" />
            <p
              data-aos="fade-up"
              className="text-xl font-semibold text-[#36606F] lemon-font min-w-[300px]"
            >
              お風呂やサウナのおともに
            </p>
          </div>
          <p
            data-aos="fade-up"
            className="text-[#36606F] px-4 mt-6 text-center text-xs font-bold"
          >
            ※付属スプーン一杯（CBD20mg）が１回摂取量の目安ですが、さらに少ない量でご満足いただける方も少なくありません。
            体質や体調に合わせて摂取量を調整してください。
          </p>
          <p
            data-aos="fade-up"
            className="text-[#36606F] text-5xl mt-8 text-center lemon-font"
          >
            豊富な使い道
          </p>
          <p
            data-aos="fade-up"
            className="text-2xl mt-8 px-4 text-[#F77D1F] font-bold"
          >
            ドリンクはもちろん、スイーツや料理に使って広がるCBDライフ
          </p>
          <div className="flex mt-10 px-5 justify-between">
            <div>
              <div className="triangle-container text-white bg-[#F77D1F] lemon-font p-2 relative w-fit font-bold">
                Recipe 1
              </div>
              <p
                data-aos="fade-up"
                className="mt-5 font-bold text-3xl lemon-font"
              >
                CBDコーヒーゼリー
              </p>
              <p data-aos="fade-up" className="text-[#36606F] font-bold mt-4">
                [材料 / 2人分]
              </p>
              <div className="mt-3 text-[#36606F] font-bold">
                <div data-aos="fade-up" className="flex justify-between">
                  <p>水</p>
                  <p className="min-w-[80px]">... 250ml</p>
                </div>
                <div data-aos="fade-up" className="flex justify-between mt-2">
                  <p>ドリップコーヒー</p>
                  <p className="min-w-[80px]">... 1袋</p>
                </div>
                <div data-aos="fade-up" className="flex justify-between mt-2">
                  <p>てんさい糖</p>
                  <p className="min-w-[80px]">... 大さじ1</p>
                </div>
                <div data-aos="fade-up" className="flex justify-between mt-2">
                  <p>ゼラチン</p>
                  <p className="min-w-[80px]">... 5g</p>
                </div>
                <div data-aos="fade-up" className="flex justify-between mt-2">
                  <p>マカロニ水溶性CBD</p>
                  <p className="min-w-[80px]">... 1/4 さじ</p>
                </div>
                <div data-aos="fade-up" className="flex justify-between mt-2">
                  <p>お好みのトッピング</p>
                  <p className="min-w-[80px]">... 適量</p>
                </div>
              </div>
            </div>
            <img data-aos="fade-up" className="w-48" src={glass} alt="glass" />
          </div>
          <div className="px-5 mt-5">
            <p
              data-aos="fade-up"
              className="bg-[#F77D1F] text-white font-bold w-fit py-2 px-3 rounded-lg"
            >
              つくりかた
            </p>
            <ol className="text-[#36606F] font-bold list-decimal list-inside">
              <li data-aos="fade-up" className="mt-2">
                水を沸騰させて、ドリップコーヒーでコーヒーを作る。
              </li>
              <li data-aos="fade-up" className="mt-2">
                ①にてんさい糖、ゼラチン、水溶性CBDを入れ混ぜて溶かす。
              </li>
              <li data-aos="fade-up" className="mt-2">
                容器に②を注ぎ、粗熱が取れたら冷蔵庫で冷やし固める。
              </li>
              <li data-aos="fade-up" className="mt-2">
                お好みでバニラアイス、ホイップクリームなどを飾り、フルーツを添える。
              </li>
            </ol>
          </div>
          <p
            data-aos="fade-up"
            className="border-t-4 border-dotted border-[#36606f] mt-5"
          ></p>
          <div className="flex mt-10 px-5 justify-between">
            <div>
              <div className="triangle-container text-white bg-[#F77D1F] lemon-font p-2 relative w-fit font-bold">
                Recipe 2
              </div>
              <p
                data-aos="fade-up"
                className="mt-5 font-bold text-3xl lemon-font"
              >
                オーツミルクの安眠ココア
              </p>
              <p data-aos="fade-up" className="text-[#36606F] font-bold mt-4">
                [材料 / 2杯分]
              </p>
              <div className="mt-3 text-[#36606F] font-bold">
                <div data-aos="fade-up" className="flex justify-between">
                  <p>ココア</p>
                  <p className="min-w-[80px]">... 大さじ1</p>
                </div>
                <div data-aos="fade-up" className="flex justify-between mt-2">
                  <p>てんさい糖</p>
                  <p className="min-w-[80px]">... 大さじ2</p>
                </div>
                <div data-aos="fade-up" className="flex justify-between mt-2">
                  <p>水</p>
                  <p className="min-w-[80px]">... 少々</p>
                </div>
                <div data-aos="fade-up" className="flex justify-between mt-2">
                  <p>オーツミルク</p>
                  <p className="min-w-[80px]">... 250ml</p>
                </div>
                <div data-aos="fade-up" className="flex justify-between mt-2">
                  <p>マカロニ水溶性CBD</p>
                  <p className="min-w-[80px]">... 1さじ</p>
                </div>
              </div>
            </div>
            <img className="w-48" src={teaCup} alt="tea" />
          </div>
          <div className="px-5 mt-8">
            <p
              data-aos="fade-up"
              className="bg-[#F77D1F] text-white font-bold w-fit py-2 px-3 rounded-lg"
            >
              つくりかた
            </p>
            <ol className="text-[#36606F] font-bold list-decimal list-inside">
              <li data-aos="fade-up" className="mt-2">
                ココアとてんさい糖を混ぜて、水で伸ばし、弱火にかける。
              </li>
              <li data-aos="fade-up" className="mt-2">
                ①が溶けてとろとろになったら、オーツミルクを入れて温める。
              </li>
              <li data-aos="fade-up" className="mt-2">
                水溶性CBDを入れて混ぜる。
              </li>
            </ol>
          </div>
          <div className="flex items-center mt-14 px-3 gap-4">
            <img className="w-44" src={magzine} alt="doc" />
            <div>
              <p data-aos="fade-up" className="text-[#36606F] shippori-font">
                X (旧Twitter) にてオリジナルレシピ随時公開中！
              </p>
              <p
                data-aos="fade-up"
                style={{ boxShadow: "4px 4px 0px 0px rgba(0,0,0,0.75)" }}
                className="bg-[#F77D1F] mt-3 text-center shadow-md text-white p-2 font-bold rounded-lg"
              >
                #マカロニ水溶性CBDレシピ
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[500px] content-div relative -top-[48.2em] seventh-section">
        <img src={snsBg} alt="layer" />
        <div className="absolute w-full top-16">
          <p
            data-aos="fade-up"
            className="text-center text-white text-5xl mb-7 lemon-font"
          >
            お客様の声
          </p>
          <p
            data-aos="fade-up"
            className="text-center font-bold text-2xl mb-7  shippori-font"
          >
            SNSやアンケートで
          </p>
          <p
            data-aos="fade-up"
            className="text-center text-white text-4xl mb-7 shippori-font"
          >
            嬉しいご感想多数！
          </p>
          <p data-aos="fade-up" className="text-center text-xs mb-10">
            ※白いラベルデザインは旧デザインのものです。
          </p>
          <div className="w-11/12 mx-auto">
            <div
              data-aos="fade-up"
              className="flex gap-5 bg-white p-3 rounded-xl relative shadow-[0_20px_60px_5px_rgba(0,0,0,0.3)]"
            >
              <p
                data-aos="fade-up"
                className="text-[#36606F] font-bold italic whitespace-nowrap text-xl lemon-font"
              >
                Voice 1
              </p>
              <p data-aos="fade-up" className="text-sm">
                いただいた自家製紫蘇シロップのジュースと
                <span className="bg-[#FFFE9C]">
                  水溶性CBDで気分もスッキリ！
                </span>
                さて明るいうちにもう少し作業をすすめちゃおっと！
              </p>
              <div
                className="w-0 h-0 -bottom-5 left-10 absolute
  border-l-[10px] border-l-transparent
  border-t-[25px] border-t-white
  border-r-[10px] border-r-transparent rotate-12 "
              ></div>
            </div>
            <div
              data-aos="fade-up"
              className="flex gap-5  mt-9 bg-white p-3 rounded-xl relative shadow-[0_20px_60px_5px_rgba(0,0,0,0.3)]"
            >
              <p
                data-aos="fade-up"
                className="text-[#36606F] font-bold italic whitespace-nowrap text-xl lemon-font"
              >
                Voice 2
              </p>
              <p data-aos="fade-up" className="text-sm">
                美容液に混ぜて１ヶ月間使用続けた結果、
                <span className="bg-[#FFFE9C]">肌質が凄く良くなりました。</span>
                肌に触れた手触りが１ヶ月前とはぜんぜん違います。
                今まで、市販のCBD化粧品使ってきましたがマカロニさんのCBDパウダーは比べ物にならないくらい効果ありました。
                使用している原材料の違いなのか？とても
                <span className="bg-[#FFFE9C]">
                  良いもの作ってくれてありがとう
                </span>
                って感じです！
              </p>
              <div
                className="w-0 h-0 -bottom-5 left-10 absolute
  border-l-[10px] border-l-transparent
  border-t-[25px] border-t-white
  border-r-[10px] border-r-transparent rotate-12 "
              ></div>
            </div>
            <div
              data-aos="fade-up"
              className="flex gap-5 mt-9 bg-white p-3 rounded-xl relative shadow-[0_20px_60px_5px_rgba(0,0,0,0.3)]"
            >
              <p
                data-aos="fade-up"
                className="text-[#36606F] font-bold italic whitespace-nowrap text-xl lemon-font"
              >
                Voice 3
              </p>
              <p data-aos="fade-up" className="text-sm">
                お風呂に入れて入浴した日の夜は
                <span className="bg-[#FFFE9C]">
                  ぐっすり眠れて、朝の目覚めがよくなりました。
                </span>
                肌も艶々になり、心地よい感じがクセになります。
              </p>
              <div
                className="w-0 h-0 -bottom-5 left-10 absolute
  border-l-[10px] border-l-transparent
  border-t-[25px] border-t-white
  border-r-[10px] border-r-transparent rotate-12 "
              ></div>
            </div>
            <div
              data-aos="fade-up"
              className="flex gap-5 mt-9 bg-white p-3 rounded-xl relative shadow-[0_20px_60px_5px_rgba(0,0,0,0.3)]"
            >
              <p
                data-aos="fade-up"
                className="text-[#36606F] font-bold italic whitespace-nowrap text-xl lemon-font"
              >
                Voice 4
              </p>
              <p data-aos="fade-up" className="text-sm">
                今日になってもまだまだコレには驚かされてる！
                <span className="bg-[#FFFE9C]">
                  寝起きの身体のダルさにスプーン半分程舐めると一瞬でスッキリ！
                </span>
                口の中でスッと溶けると同時に重たいものパッと脱ぎ捨てたみたいに心身が軽くなる。
                無敵の元気マンに変身!!って感じ！
                <span className="bg-[#FFFE9C]">
                  ほんとビックリするから試してみて!!
                </span>
              </p>
              <div
                className="w-0 h-0 -bottom-5 left-10 absolute
  border-l-[10px] border-l-transparent
  border-t-[25px] border-t-white
  border-r-[10px] border-r-transparent rotate-12 "
              ></div>
            </div>
            <div
              data-aos="fade-up"
              className="flex gap-5 mt-9 bg-white p-3 rounded-xl relative shadow-[0_20px_60px_5px_rgba(0,0,0,0.3)]"
            >
              <p
                data-aos="fade-up"
                className="text-[#36606F] font-bold italic whitespace-nowrap text-xl lemon-font"
              >
                Voice 5
              </p>
              <p data-aos="fade-up" className="text-sm">
                <span className="bg-[#FFFE9C]">
                  生理初日いつもなら痛み止め飲まないと我慢できないのに、
                </span>
                朝マカロニさんの水溶性CBDを付属のスプーン山盛り目に直食べしたおかげか
                <span className="bg-[#FFFE9C]">まだ薬飲んでません。</span>
                そろそろ追加食べしようと思います。マジですごい…
              </p>
              <div
                className="w-0 h-0 -bottom-5 left-10 absolute
  border-l-[10px] border-l-transparent
  border-t-[25px] border-t-white
  border-r-[10px] border-r-transparent rotate-12 "
              ></div>
            </div>
          </div>
          <p
            data-aos="fade-up"
            className="text-white font-semibold mt-14 w-11/12 mx-auto text-sm leading-loose"
          >
            ご使用上の注意：湿気、光に弱いため使用後はすぐに蓋をしめて保管してください。
            なるべく涼しく湿気が少ない常温の場所に保管してください。
            開封後1ヶ月以内に使用してください。もし期間内に使用できなかった場合はお風呂に入れてご自宅CBD温泉としてご活用ください。
            乳幼児、小児の手の届かない所においてください。体調、体質により、まれに合わない場合がありますので、その場合はご使用をお控え下さい。
            疾病療養中の方、妊婦・授乳中の方は医師にご相談の上ご使用ください。
            特定原材料(卵、乳、小麦、えび、カニ、落花生、そば)は含まれておりません。
            原材料を確認の上、食物アレルギーのある方はお召し上がりにならないでください。お体に合わない場合は使用を中止してください。
          </p>
        </div>
      </div>
      <div className="w-[500px] content-div bg-[#ED794F] relative pt-5 -top-[48.2em] eighth-section">
        <p
          data-aos="fade-up"
          className="text-white text-center p-3 uppercase text-4xl"
        >
          Baseでのご購入はこちら
        </p>
        <div data-aos="fade-up" className="bg-white p-4 w-11/12 mx-auto mb-4">
          <img src={base1} alt="base" />
          <div className="flex my-4 relative">
            {/* <p
              data-aos="fade-up"
              className="absolute z-10 font-bold w-full text-center text-xl"
            >
              Base用バナー
            </p>
            <p
              data-aos="fade-up"
              className="absolute bottom-1 z-10 font-bold w-full text-center text-xl"
            >
              (クリックすると上に大きく表示される)
            </p> */}
            <ImageModal imageUrl={require("../assets/BASE_suiyousei01.png")} />
            <ImageModal imageUrl={require("../assets/BASE_suiyousei02.png")} />
            <ImageModal imageUrl={require("../assets/BASE_suiyousei03.png")} />
            <ImageModal imageUrl={require("../assets/BASE_suiyousei04.png")} />
            <ImageModal imageUrl={require("../assets/BASE_suiyousei05.png")} />
            <ImageModal imageUrl={require("../assets/BASE_suiyousei06.png")} />
            <ImageModal imageUrl={require("../assets/BASE_suiyousei07.png")} />
            {/* <img className="w-2/12 blur-[2px]" src={first} alt="first" />
            <img className="w-2/12 blur-[2px]" src={second} alt="second" />
            <img className="w-2/12 blur-[2px]" src={third} alt="third" />
            <img className="w-2/12 blur-[2px]" src={four} alt="four" />
            <img className="w-2/12 blur-[2px]" src={five} alt="five" />
            <img className="w-2/12 blur-[2px]" src={six} alt="six" /> */}
          </div>
          <p className="text-center font-bold text-2xl">
            Water Soluble Hemp Powder
          </p>
          <p className="text-center font-bold text-2xl">
            (水溶性CBDパウダー)
          </p>

          <a
            href="https://shop.macaronicbd.co.jp/items/77225090"

            target="blank"
            className="bg-[#74BEC5] block text-center border-[1px] p-2 text-2xl border-[#36606F] mt-5 font-bold"
          >
            <span>5 / 10g</span>
          </a>
          <a
            href="https://shop.macaronicbd.co.jp/items/78202704"

            className="bg-[#74BEC5] block relative text-center border-[1px] p-2 text-2xl border-[#36606F] mt-5 font-bold"
            target="blank"
          >
            <p >
              {" "}
              <span className="text-[#FF0000] absolute left-4">26%お得</span> 5g
              定期便
            </p>
          </a>
          <a
            href="https://shop.macaronicbd.co.jp/items/77625657"

            target="blank"
            className="bg-[#74BEC5] block relative text-center border-[1px] p-2 text-2xl border-[#36606F] mt-5 font-bold"
          >
            <p>
              {" "}
              <span className="text-[#FF0000] absolute left-4">
                37%お得
              </span>{" "}
              10g 定期便
            </p>
          </a>
          <p

            className="text-[#FF0000] font-bold text-4xl text-center mt-8"
          >
            <span className="text-black text-xl">価格 </span>
            7500円 <span className="text-sm">(税込)</span>
          </p>
        </div>
      </div>
      <div
        className={`fixed-section fixed z-10 bottom-0 pt-5 max-w-[500px] ${
          isVisible ? "block " : "hidden"
        }`}
      >
        <a target="blank" href="https://shop.macaronicbd.co.jp">
          <img src={waterSoluble2} alt="water-soluble-2" />
        </a>
      </div>
    </div>
  );
};

export default LCP;
