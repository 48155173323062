import React from "react";
import { pengiun } from "../assets";

const CBDSection = ({ imageSrc, children, className, styles, id }) => {
  return (
    <div
      id={id}
      className={`cbd-section w-100 bg-white ${styles} xl:ml-7  border-b-2 border-r-2 border-[#dcd9d7]`}
    >
      <div className="image-div flex items-center">
        <img
          data-aos="fade-up"
          data-aos-delay="300"
          src={pengiun}
          alt="CBD Source"
          className="cbd--pengiun-image w-9 mr-4"
        />
        <img
          data-aos="fade-up"
          data-aos-delay="300"
          src={imageSrc}
          alt="CBD Source"
          className={className}
        />
      </div>
      <div className="cbd-content sm:px-10 py-10 px-6">{children}</div>
    </div>
  );
};

export default CBDSection;
