import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // モーダルのルート要素を設定

const ImageModal = ({ imageUrl }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div>
      <img
        src={imageUrl}
        onClick={() => setModalIsOpen(true)}
        style={{ cursor: 'pointer' }}
        alt="Thumbnail"
      />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Expanded Image"
        style={{
          content: {
            position: 'fixed', // モーダルを画面に固定
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '90vw', // 幅をビューポートの90%に設定
            height: '90vh', // 高さをビューポートの90%に設定
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'transparent',
            border: 'none',
            padding: 0,
            overflow: 'visible', // 画像がモーダルをはみ出しても表示されるようにする
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            zIndex: 1000,
          },
        }}
      >
        <button
          onClick={() => setModalIsOpen(false)} // ボタンをクリックしたときにモーダルを閉じる
          style={{
            position: 'absolute', // ボタンをモーダルの右上に配置
            top: '10px', // 上からの距離
            right: '10px', // 右からの距離
            background: 'none', // 背景を透明に設定
            border: 'none', // 枠線を消去
            fontSize: '25px', // ボタンのサイズを設定
            color: 'white', // ボタンの色を設定
            cursor: 'pointer', // カーソルをポインターに設定
          }}
        >
          &times; {/* これは「×」記号です */}
        </button>

        <img
          src={imageUrl}
          alt="Expanded"
          style={{
            maxHeight: '85vh', // 高さの最大値をビューポートの85%に設定
            maxWidth: '85vw', // 幅の最大値をビューポートの85%に設定
            objectFit: 'contain',
            width: 'auto', // 幅を自動調整
            height: 'auto' // 高さを自動調整
          }}
        />
      </Modal>
    </div>
  );
};

export default ImageModal;
