import React from "react";
import Header from "./Header";
import { bg } from "../assets";
// relative 2xl:pl-28 2xl:pr-52 sm:px-14 px-6

const ContainerWrapper = ({ children }) => {
  return (
    <>
      <div className="relative 2xl:pl-28 2xl:pr-28 sm:px-14 px-6">
        <div
          className="bg-image-div w-full bg-cover min-h-screen left-0 right-0 fixed z-[-1]"
          style={{ backgroundImage: `url(${bg})` }}
        ></div>
        <Header />
        <div className="content">{children}</div>
      </div>
    </>
  );
};

export default ContainerWrapper;
